import IoT from "../../images/ExpertisePage/OurExpertiseCards/cardCovers/Internet_of_Things_Solutions.png"
import AWS from "../../images/ExpertisePage/OurExpertiseCards/cardCovers/AWS_Cloud_Solutions.png"
import Cloud from "../../images/ExpertisePage/OurExpertiseCards/cardCovers/Cloud_Solutions.png"
import RTC from "../../images/ExpertisePage/OurExpertiseCards/cardCovers/Real_Time_Communication.png"
import Flutter from "../../images/ExpertisePage/OurExpertiseCards/cardCovers/Flutter_Mobile_App_Development.png"
import UIUX from "../../images/ExpertisePage/OurExpertiseCards/cardCovers/UI_UX.png"
import MAD from "../../images/ExpertisePage/OurExpertiseCards/cardCovers/Madical_App_Dev.png"

const expretiseCardData = {
  expertises: [
    {
      id: 0,
      picture: MAD,
      title: "Medical App Development",
      href: "/expertise/medical-app-development-services/",
      desc:
          "Our medical app developers successfully create healthcare applications for hospitals, clinics and medical offices. For our clients, we develop hospital management software, doctor appointment applications, patient diagnostics solutions and more.",
    },
    {
      id: 1,
      picture: Flutter,
      title: "Flutter Mobile App Development",
      href: "https://flutter.os-system.com/",
      desc:
        "Our expert developers create flutter mobile solutions that will meet your user, business, industry and needs. We use the latest flutter development technologies to deliver the highest level of quality guaranteed.",
    },
    {
      id: 2,
      picture: IoT,
      title: "Internet of Things Solutions",
      //href: "/expertise/internet-of-things/", // route to old page
      href: "https://iot.os-system.com/",
      desc:
        "We excel at creating modern IoT solutions for a wide variety of industries. Our engineers have strong experience in unique solution development for the advanced devices and tools.",
    },
    {
      id: 3,
      picture: AWS,
      title: "AWS Cloud Solutions",
      //href: "/expertise/internet-of-things/", // route to old page
      href: "https://aws.os-system.com/",
      desc:
          "We specialize in tailor-made AWS Cloud Solutions that are secure, scalable, and cost-effective. Our expert developers and cutting-edge technology ensure high compliance and quality levels to meet your unique business needs.",
    },
    {
      id: 4,
      picture: Cloud,
      title: "Cloud Solutions",
      href: "/expertise/cloud-solutions/",
      desc:
        "We can improve your product with the secure cloud solutions and help it reach the new heights. Our developers are capable to create a strong cloud infrastructure for your project.",
    },
    {
      id: 5,
      picture: RTC,
      title: "Real Time Communication",
      href: "/expertise/rtc/",
      desc:
        "We provide WebRTC development and develop complex communication systems using modern methods and latest technologies.",
    },
    {
      id: 6,
      picture: UIUX,
      title: "UI/UX",
      href: "/expertise/uiuxdesign/",
      desc:
        "Our designers have strong experience in transforming our customer’s needs into intuitive user interfaces to achieve business goals and deliver success. We carefully analyze the requirements of the project to define the most effective and engaging UI/UX design.",
    },
  ],
}

export default expretiseCardData
