import React from "react"
import SEO from "../components/CommonComponents/SEO"
import Layout from "../components/CommonComponents/Layout"
import Header from "../components/Expertise/Header"
import OurExpertiseCards from "../components/Expertise/OurExpertiseCards"
import FooterRocket from "../components/CommonComponents/FooterRocket"
import { mainUrl } from "../js/config"

export default function Expertise() {
  return (
    <div>
      <SEO
        title="Custom Software Solutions to Realize Your Ideas"
        description="Looking for custom IT solutions? The OS-System team has 15 years of experience in developing and realizing IT projects."
        canonical={`${mainUrl}/expertise/`}
      />
      <Layout showFormPoint={100}>
        <Header />
        <OurExpertiseCards />
        <FooterRocket />
      </Layout>
    </div>
  )
}
