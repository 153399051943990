import React from 'react';

import './Header.scss';

export default function Header() {
  return (
    <div className='exp-header-container'>
      <div className='exp-header-wrapper'>
        <div className='exp-header-text'>
          <p className='exp-header-upper-title'>OS-System – IT Services Provider</p>
          <h1 className='exp-header-title'>Our expertise</h1>
          <p className='exp-header-bottom-title'>We design, build and deploy innovative solutions<br/>to empower the business of our customers</p>
        </div>
      </div>
    </div>
  )
}
