import React from "react"

import GatsbyLink from "/src/components/CommonComponents/GatsbyLink"

import arrow from "../../../images/ExpertisePage/OurExpertiseCards/arrow.png"
import expretiseCardData from "../../../information/Expertise/expertise.js"

import "./ourExpertiseCards.scss"

class OurExpertiseCards extends React.Component {
  constructor(props) {
    super(props)
  }

  renderOurExpertiseCards = ({ id, picture, title, desc, href }) => (
    <GatsbyLink to={href} key={id} className="expertise-wrapper-item">
      <div>
        <div
          className="expertise-wrapper-item-img"
          style={{
            backgroundImage: `url(${picture})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "center",
            backgroundPositionY: "top",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="expertise-wrapper-item-textblock">
          <div className="expertise-wrapper-item-title">{title}</div>
          <p>{desc}</p>
        </div>
      </div>
      <div className="arrow-block-wrapper">
        <div className="arrow-block">
          <div>Learn more</div>
          <div
            className=""
            style={{
              background: `url(${arrow})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left center",
            }}
          ></div>
        </div>
      </div>
    </GatsbyLink>
  )

  render() {
    return (
      <div className="expertise-wrapper-container">
        <section className="expertise-wrapper-parent width-container">
          {expretiseCardData.expertises.map(current =>
            this.renderOurExpertiseCards(current)
          )}
        </section>
      </div>
    )
  }
}
export default OurExpertiseCards
